<template lang="pug">

.test-suite.content-styles
  h1 Test Suite
  p Sign-in Status: {{ userSignedIn }}
  button.button.style-1(@click="signIn") Sign In
  button.button.style-2(@click="logOut") Log Out

  Registration

</template>

<script>

import { auth, db } from '@/firebase';

import Registration from '@/components/user/Registration.vue'

// import { useUserStore } from '@/stores/user';

import { ref, set, onValue } from "firebase/database";


export default {
  name: 'TestUserSystem',
  components: {
    Registration
  },
  data(){
    return {
      showOverlay: false,
      userSignedIn: false,
      //user: useUserStore()
    }
  },
  mounted(){
    

    const runTest = async () => {
      //console.log( 'Testing firestore...' );
      await this.test();
    }

    //runTest();

  },
  methods: {

    async test(){
      //this.writeTestData( 456, 7442384 );

      //console.log( 'database:', db );return;
      //const usersCollection = db.collection('users');
      //console.log( db.collection );
      //return;

      const userRef = ref( db, 'test/7442384' );
      //console.log( userRef );
      const val = onValue( userRef )
      //.then(( snapshot ) => { console.log( snapshot.val() ); })
      console.log( val );
    },

    checkSignInStatus(){
      
      if( this.user.name !== '' ) this.userSignedIn = true;
      const component = this;
      auth.onAuthStateChanged(async (user) => {
        if( user ) {
          component.userSignedIn = true;
          console.log( user );
          

        } else {
          component.userSignedIn = false;
        }
      });

    },


    getUserData( prop, placeholder ){
      if( this.user[prop] && this.user[prop] !== '' ) return this.user[prop];
      return placeholder || '<unknown>';
    },
    //@TODO: sanitize value
    setUserData( prop, value ){
      try {
        if( this.user[prop] ) this.user[prop] = value;
      } catch( e ){

      }
    },

    writeTestData( message, id ){

      //const db = getDatabase();

      let rando = '';

      for (let i = 0; i < 7; i++) {
        const randomNumber = Math.floor(Math.random() * 10); // Generate a random number between 0 and 9
        rando += randomNumber.toString();
      }

      set( ref( db, 'test/' + id || rando ), {
        message: message
      });

      console.log( 'Wrote test data to', rando, '|', message );

    },


    async signInWithGoogle() {
      const provider = new GoogleAuthProvider;
      console.log( signInWithPopup );
      try {
        await signInWithPopup( provider );
        // User is signed in.
      } catch( error ){
        console.error( error );
      }
    },

    async signIn(){
      await this.signInWithGoogle();
      
    },

    logOut(){
      console.log( '@TODO: add way to log out.' );      
      auth.signOut();
    }

  }
}

</script>

<style scoped>

@import url(https://alexsellsrichmond.com/files/css/core.css);

@import url(https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css);

@import url(https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css);

button {
  margin: 1em
}
</style>